import {useDispatch} from "react-redux";
import {closePopup} from "../../../../ducks/popup-duck.js";
import {useState} from "react";
import Button from "../../../util/Button.jsx";
import './AddRate.scss'
import AddIcon from "mdi-react/AddIcon.js";
import dayjs from "dayjs";
import {postRateApi} from "../../../../api/statusApi.js";

export default ({currentRate, assetName, assetId}) => {

    const dispatch = useDispatch()
    const [isPosting, setIsPosting] = useState(false)
    const [date, setDate] = useState(dayjs().format('YYYY-MM-DD'))
    const [rate, setRate] = useState(currentRate)

    const postRate = async () => {
        setIsPosting(true)
        await postRateApi({
            assetId,
            date,
            rate,
        })
        dispatch(closePopup())
    }

    return (
        <div className='add-rate'>
            <h2>
                Add rate for {assetName}
            </h2>
            <div className='flex-vertical-center'>
                <input
                    type='date'
                    value={date}
                    onChange={e => setDate(e.target.value)}
                />
                <input
                    type='number'
                    value={rate}
                    placeholder={'new rate'}
                    onChange={e => setRate(e.target.value)}
                />
            </div>
            <div className='button-container'>
                <Button
                    onClick={postRate}
                    disabled={isPosting}
                    icon={<AddIcon/>}
                >
                    Add
                </Button>
            </div>
        </div>
    )
}