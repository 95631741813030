import Button from "../../../util/Button.jsx";
import './AssetOptions.scss'
import TagPlusOutlineIcon from "mdi-react/TagPlusOutlineIcon.js";
import {useDispatch, useSelector} from "react-redux";
import {activateWidePopup} from "../../../../ducks/popup-duck.js";
import AddRate from "./AddRate.jsx";
import {getCurrentAsset} from "../../../../ducks/assets-duck.js";
import {getIsOddy} from "../../../../ducks/user-duck.js";

export default () => {

    const dispatch = useDispatch()
    const isOddy = useSelector(getIsOddy)

    const {
        currentRate,
        id: assetId,
        name: assetName,
    } = useSelector(getCurrentAsset)

    return isOddy && (
        <div className='asset-options'>
            <Button
                icon={<TagPlusOutlineIcon/>}
                onClick={() => dispatch(activateWidePopup(<AddRate currentRate={currentRate} assetId={assetId} assetName={assetName}/>))}
            >
                Add rate
            </Button>
        </div>
    )
}