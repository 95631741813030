import {useState} from "react";
import {getAllAssets} from "../../ducks/assets-duck.js";
import {useSelector} from "react-redux";
import {useChillEffect} from "../../util/mounting-hooks.js";
import {MIN} from "../../util/time-and-date.js";
import {DAI_ASSET_ID} from "../../util/misc.js";

const CHANGE_VALUE_THRESHOLD = 100

export const useChanges = () => {

    const assets = useSelector(getAllAssets)

    const [changeList, setChangeList] = useState([])
    const [oldAssets, setOldAssets] = useState([])

    useChillEffect(() => {
        if (oldAssets.length > 0) {
            let newChangeList = changeList.filter(change => change.timestamp > Date.now() - (5 * MIN))
            for (let i = 0; i < oldAssets.length; i++) {
                const oldAsset = oldAssets[i]
                const currentAsset = assets[i]
                const diff = currentAsset.value - oldAsset.value
                if(Math.abs(diff) > CHANGE_VALUE_THRESHOLD && currentAsset.id !== DAI_ASSET_ID){
                    newChangeList.push({
                        timestamp: Date.now(),
                        diff,
                        assetName: currentAsset.name,
                        assetCode: currentAsset.code,
                        assetId: currentAsset.id,
                    })
                }
                setChangeList(newChangeList)
            }
        }
        setOldAssets(assets)
    }, [
        assets
    ])

    return changeList
}